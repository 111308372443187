import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [audioPlaying, setAudioPlaying] = useState(false);

  function playAudio() {
    setAudioPlaying(true);
  }
  return (
    <div id="app" style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", overflow: "auto", backgroundImage: "url('./free.png')", backgroundSize: "cover" }}>

      <div className="header" style={{ position: "absolute", top: "20px", left: 0, right: 0, textAlign: "center", justifyContent: 'revert-layer' }}>
        <a href="https://twitter.com/Palestine_S0L" onClick={playAudio} target="_blank" className="icon-link" style={{ padding: 20, fontSize: 28 }}>
          twitter
        </a>
        <a href="https://t.me/Palestine_Sol" onClick={playAudio} target="_blank" className="icon-link" style={{ padding: 20, fontSize: 28 }}>
          Telegram
        </a>
        <a href="https://www.pump.fun/EPensFkWLC9wNfu5GzsB3DXLa1TLYWujgxbsWKzN7JWb" onClick={playAudio} style={{ padding: 20, fontSize: 28 }} target="_blank" className="icon-link">
          Pump
        </a>
        {/* Add your third image here */}
      </div>
      <div id="hero" style={{ position: "absolute", top: "240px", left: 0, right: 0, textAlign: "center", fontSize: "50px" }}>
        Free of Palestine 🇵🇸
        {/* <div > Hope for world peace</div> */}
      </div>
      {audioPlaying && <audio src="/return.mp3" autoPlay />}
      {/* <div className="balloon" onClick={playAudio}> */}
      {/* <img  src="/q.png" className="icon" /> */}
      {/* </div> */}
    </div>
  );
}

export default App;
